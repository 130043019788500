// @flow
import React from "react";
import {
    InspectionDefineReason,
    Layout,
    CreateInspectionLayout,
    withPrivateRoute,
} from "@containers";

/**
 * Reasons Page
 */
const NewInspectionReasons = (props: *) => (
    <Layout {...props}>
        <CreateInspectionLayout>
            <InspectionDefineReason />
        </CreateInspectionLayout>
    </Layout>
);

export default withPrivateRoute(NewInspectionReasons);
